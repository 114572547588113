.list{
    .winners {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 2px;
        list-style-type: none;
        // width: 80%;
        margin: auto;
        text-transform: uppercase;
        display: grid;
        grid-template: "a a a a" auto;
        .winner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 15px 10px 0px 10px;
            padding: 15px 20px;
            background-color: rgba(154, 72, 209, 0);
            transition: 0.1s;
            color: rgb(148, 255, 237);
            text-shadow: 0px 0px 4px rgb(0 255 255 / 50%);
            border: 2px solid rgba(72, 193, 209, 0.7);
            border-radius: 4px;
            &:hover{
                // transform: scale(1);
                transition: .2s;
                // box-shadow: 0px 0px 15px rgba(72, 193, 209, 0.7);
            }
        }
        .not-show {
            display: none;
        }
    }
}

.slot__outer {
    background: #ffbf1f url('../assets/images/light-blubs.0d2ddfd6.svg');
    box-shadow: 0.625rem 0.625rem 0 rgb(0 0 0 / 20%);
    height: 14.375rem;
    margin: 0 auto;
    padding: 1.625rem;
    width: 62.5rem;
    z-index: 3;
}

.premio{
    color: #ffbf1f;
    font-size: 14px;
    margin: 12px 0px;
}