:root {
	--clr-neon: rgb(43, 154, 245);
    --clr-neon2: rgb(245, 43, 43);
	--clr-white: rgba(255, 255, 255, 1);
}

.btn-sortear {
    z-index: 1;
    margin-top: 40px;
    padding: 40px 20px;
    border-radius: 50%;
    font-weight: 700;
    outline: none;
    cursor: url('../assets/cursor.cur'), auto;
    font-size: 2em;
	text-decoration: none;
	color: var(--clr-neon);
	border: var(--clr-neon) 2px solid;
	box-shadow: inset 0 0 0.5em 0 var(--clr-neon), 0 0 0.5em 0 var(--clr-neon);
	position: relative;
}

.btn-sortear::before {
	pointer-events: none;
	content: "";
	position: absolute;
	background: var(--clr-neon);
	top: 100%;
	left: 0;
	width: 100%;
	height: 100%;
	transform: perspective(1.2em) rotateX(40deg) scale(1.5, 0.5);
	filter: blur(1.15em);
	opacity: 0.7;
	transition: transform 0.1s linear;
}

.btn-sortear::after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	box-shadow: 0 0 4em 0.6em var(--clr-neon), 0 0 1em 0.2em var(--clr-white);
	opacity: 0;
	background: var(--clr-neon);
	z-index: -1;
	transition: opacity 0.1s linear;
    border-radius: 50%;
}

.btn-sortear:hover,
.btn-sortear:focus {
	color: var(--clr-bg);
	z-index: 20;
}

.btn-sortear:hover::before,
.btn-sortear:focus::before {
	opacity: 1;
	transform: perspective(1em) rotateX(40deg) scale(1.5, 0.6);
	transition: transform 0.1s linear;
}

.btn-sortear:hover::after,
.btn-sortear:focus::after {
	opacity: 1;
}
