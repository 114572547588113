.btn-reset {
    z-index: 1;
    background-color: black;
    margin-top: 50px;
    padding: 20px 10px;
    border-radius: 50%;
    font-weight: 700;
    outline: none;
    cursor: url('../assets/cursor.cur'), auto;
    font-size: 1em;
	text-decoration: none;
	color: var(--clr-neon2);
	border: var(--clr-neon2) 2px solid;
	box-shadow: inset 0 0 0.5em 0 var(--clr-neon2), 0 0 0.5em 0 var(--clr-neon2);
	position: relative;
}

.btn-reset::before {
	pointer-events: none;
	content: "";
	position: absolute;
	background: var(--clr-neon2);
	top: 100%;
	left: 0;
	width: 100%;
	height: 100%;
	transform: perspective(1.2em) rotateX(40deg) scale(1.5, 0.5);
	filter: blur(1.15em);
	opacity: 0.7;
	transition: transform 0.2s linear;
}

.btn-reset::after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	box-shadow: 0 0 4em 0.6em var(--clr-neon2), 0 0 1em 0.2em rgb(133, 34, 34);
	opacity: 0;
	z-index: -1;
	transition: opacity 0.2s linear;
    border-radius: 50%;
}

.btn-reset:hover,
.btn-reset:focus {
	color: var(--clr-neon2);
}

.btn-reset:hover::before,
.btn-reset:focus::before {
	opacity: 1;
	transform: perspective(1em) rotateX(40deg) scale(1.5, 0.6);
	transition: transform 0.2s linear;
}

.btn-reset:hover::after,
.btn-reset:focus::after {
	opacity: 1;
}
