@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;500;600&display=swap');
body {
  margin: 0;
  font-family: 'Lexend Deca', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #232526;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2b2b2c #1e2020);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2b2b2c, #1e2020); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  cursor: url('./components/assets/cursor.cur'), auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Background {
	cursor: url('./components/assets/cursor.cur'), auto;
}
